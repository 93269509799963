import { Box } from '@chakra-ui/react'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import { ToolboxView } from '../ToolboxView'
import { AnalyticEvent, Analytics } from '../Analytics'
export interface MarkerConfig {
	position: google.maps.LatLng
	icon?: string | google.maps.Icon
	label?: string
	zIndex?: number
}

interface MarkerPair {
	options: MarkerConfig
	marker: google.maps.Marker
}

interface MapProps {
	style?: React.CSSProperties
	onClick?: (e: google.maps.MapMouseEvent) => void
	onIdle?: (map: google.maps.Map) => void
	children?: React.ReactNode
	mapOptions?: google.maps.MapOptions
	markers?: MarkerConfig[]
}

export function Map({
	style,
	onClick,
	onIdle,
	children,
	mapOptions,
	markers,
}: MapProps) {
	const ref = React.useRef<HTMLDivElement>(null)
	const [map, setMap] = React.useState<google.maps.Map>()

	React.useEffect(() => {
		if (ref.current) {
			const newMap = new window.google.maps.Map(ref.current, mapOptions)
			setMap(newMap)
			newMap.addListener('click', () => {
				setSelectedMarkerID(null)
			})

			if (markers) {
				let newMarkerMap: { [key: string]: MarkerPair } = {}
				for (const markerOptions of markers) {
					const markerID = uuidv4()
					const options: google.maps.MarkerOptions = {
						position: markerOptions.position,
						icon: markerOptions.icon,
						zIndex: markerOptions.zIndex,
						clickable: true,
						animation: google.maps.Animation.DROP,
						map: newMap,
					}

					const newMarker = new google.maps.Marker()
					newMarker.setOptions(options)
					newMarker.addListener('click', () => {
						setSelectedMarkerID((previousMarkerID) => {
							return previousMarkerID == markerID ? null : markerID
						})
					})

					newMarkerMap[markerID] = {
						options: markerOptions,
						marker: newMarker,
					}
				}
				setMarkerMap(newMarkerMap)
			}
		}

		Analytics.record(AnalyticEvent.mapImpression)
	}, [])

	const [markerMap, setMarkerMap] = React.useState<{
		[key: string]: MarkerPair
	}>({})
	const [infoWindow, setInfoWindow] =
		React.useState<google.maps.InfoWindow | null>(null)
	const [selectedMarkerID, setSelectedMarkerID] = React.useState<
		string | null
	>(null)
	React.useEffect(() => {
		infoWindow?.close()

		let newInfoWindow: google.maps.InfoWindow | null = null
		if (selectedMarkerID) {
			const pair = markerMap[selectedMarkerID]
			if (pair.options.label) {
				newInfoWindow = new google.maps.InfoWindow()
				newInfoWindow.setContent(pair.options.label)
				newInfoWindow.open(map, pair.marker)
				Analytics.record(AnalyticEvent.mapMarkerSelected)
			}
		} else {
			Analytics.record(AnalyticEvent.mapMarkerUnselected)
		}
		setInfoWindow(newInfoWindow)
	}, [selectedMarkerID])

	function mapChildren() {
		let children: React.ReactElement[] | null | undefined
		children = React.Children.map(children, (child) => {
			if (React.isValidElement(child)) {
				// set the map prop on the child component
				return React.cloneElement(
					child as React.ReactElement<{ map: google.maps.Map }>,
					{ map: map }
				)
			}
		})
		return children
	}

	return (
		<>
			<Box ref={ref} height="100vh" width="100vw" />
			{mapChildren()}
		</>
	)
}
