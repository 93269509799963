import {
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import React from 'react'

export function ErrorModal(options: {
	errorMessage: string | null
	modalDidClose: () => void
}) {
	const { isOpen, onOpen, onClose } = useDisclosure()

	React.useEffect(() => {
		if (options.errorMessage && !isOpen) {
			onOpen()
		}
	}, [options.errorMessage])

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			onCloseComplete={options.modalDidClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>We got an error :(</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text>{options.errorMessage}</Text>
				</ModalBody>

				<ModalFooter>
					<Button onClick={onClose}>Close</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
