import { SearchIcon, EditIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Center,
	HStack,
	Input,
	Spacer,
	Text,
	VStack,
} from '@chakra-ui/react'
import React from 'react'
import { AnalyticEvent, Analytics } from './Analytics'
export function ToolboxView(options: { handleSearch: () => void }) {
	function handleSearchAgain() {
		Analytics.record(AnalyticEvent.toolboxViewSearchAgain)
		options.handleSearch()
	}

	return (
		<Box
			style={{
				position: 'fixed',
				display: 'block',
				top: 25,
				left: 25,
				maxWidth: '50vw',
				zIndex: 1,
				backgroundColor: 'white',
				padding: 25,
				borderRadius: 25,
			}}
		>
			<VStack>
				<Button onClick={handleSearchAgain}>
					<HStack>
						<SearchIcon />
						<Text>Search again</Text>
					</HStack>
				</Button>
			</VStack>
		</Box>
	)
}
