import {
	Box,
	Button,
	Heading,
	Input,
	Spacer,
	Spinner,
	VStack,
} from '@chakra-ui/react'
import React from 'react'
import { AnalyticEvent, Analytics } from './Analytics'
export function Search(options: {
	isLoading: boolean
	handleClick: (value: string) => void
}) {
	React.useEffect(() => {
		Analytics.record(AnalyticEvent.searchAddressImpression)
	}, [])

	function handleSubmit(source: string) {
		Analytics.record(AnalyticEvent.searchAddressSubmit, { source })
		options.handleClick(address)
	}

	const [address, setAddress] = React.useState<string>('')

	return (
		<Box>
			<Spacer height={'100'} />
			<VStack>
				<Heading paddingBottom={4}>
					Find what's nearby your future neighborhood
				</Heading>
				<Input
					placeholder="search address"
					value={address}
					onChange={(event) => setAddress(event.target.value)}
					onKeyDown={(event) => {
						if (event.key == 'Enter') {
							handleSubmit('enter')
						}
					}}
					enterKeyHint="search"
				/>
				<Button
					disabled={options.isLoading}
					colorScheme="teal"
					size="md"
					width={'full'}
					onClick={() => handleSubmit('button')}
				>
					{options.isLoading ? <Spinner /> : 'Search'}
				</Button>
			</VStack>
		</Box>
	)
}
