import mixpanel from 'mixpanel-browser'

export enum AnalyticEvent {
	searchAddressImpression = 'Search Address Impression',
	searchAddressSubmit = 'Search Address Submitted',
	mapImpression = 'Map Impression',
	mapMarkerSelected = 'Map Marker Selected',
	mapMarkerUnselected = 'Map Marker Unselected',
	toolboxViewSearchAgain = 'Toolbox View Search Again',
}

export class Analytics {
	static configure() {
		const apiKey = process.env.REACT_APP_MIXPANEL_API_KEY ?? ''
		const isDebugEnabled = process.env.NODE_ENV == 'development'
		mixpanel.init(apiKey, { debug: isDebugEnabled })
	}

	static record(event: AnalyticEvent, properties?: { [key: string]: any }) {
		mixpanel.track(event, properties)
	}
}
