import React, { useEffect } from 'react'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import {} from '@googlemaps/js-api-loader'
import { Map, MarkerConfig } from './maps/Map'
import { Marker } from './maps/Marker'
import { Search } from './Search'
import {
	Box,
	Center,
	Container,
	Flex,
	HStack,
	Spacer,
	Spinner,
	VStack,
} from '@chakra-ui/react'
import { ToolboxView } from './ToolboxView'
import { ErrorModal } from './ErrorModal'
import { Analytics } from './Analytics'

interface Coordinate {
	latitude: number
	longitude: number
}

interface MapDetails {
	pointsOfInterest: AddressDetails[]
	location: AddressDetails
	transit: StationInfo[]
}
interface AddressDetails extends Coordinate {
	name: string
	types: string[]
	address: string
	imageURL: string
}

interface TrainLine {
	name: string
	imageURL: string
}
interface StationInfo extends Coordinate {
	stopName: string
	trainLines: TrainLine[]
}

function App() {
	useEffect(() => {
		Analytics.configure()
	}, [])

	const [mapDetails, setMapDetails] = React.useState<MapDetails | null>(null)
	const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	async function handleClick(address: string) {
		setIsLoading(true)
		const url = `/api/v1/apartment-utilities/lookup?address=${encodeURIComponent(
			address
		)}`

		const response = await fetch(url)
		if (response.status == 200) {
			const result: MapDetails | null = await response.json()
			setMapDetails(result)
		} else if (response.status == 500) {
			const errorMessage = await response.text()
			console.log(errorMessage)
			setErrorMessage(errorMessage)
		}
		setIsLoading(false)
	}

	function buildLatLng(coordinate: Coordinate): google.maps.LatLng {
		return new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
	}

	function generateOptions(): MarkerConfig[] {
		let options: MarkerConfig[] = []

		if (mapDetails) {
			options.push({
				position: buildLatLng(mapDetails.location),
			})

			mapDetails.pointsOfInterest.forEach((pointOfInterest) => {
				options.push({
					position: buildLatLng(pointOfInterest),
					label: pointOfInterest.name,
					icon: pointOfInterest.imageURL,
				})
			})

			mapDetails.transit.forEach((station) => {
				const markerLocation = buildLatLng(station)
				station.trainLines.forEach((trainLine, index) => {
					// assuming all train line images will be 50x50 (probably shouldn't hardcode this)
					const [iconWidth, iconHeight] = [50, 50]

					// default icon anchor is at (0,0) of image (top left corner)
					// let's anchor it to the bottom center of image
					let centeredAnchor = new google.maps.Point(
						iconWidth / 2,
						iconHeight
					)
					// offset each anchor by half their width
					centeredAnchor.x -= index * (iconWidth * 0.5)

					const icon: google.maps.Icon = {
						url: trainLine.imageURL,
						anchor: centeredAnchor,
					}

					options.push({
						position: markerLocation,
						icon,
						zIndex: index,
					})
				})
			})
		}

		return options
	}

	function generateContent() {
		if (mapDetails) {
			return (
				<>
					<ToolboxView handleSearch={() => setMapDetails(null)} />
					<Map
						mapOptions={{
							center: buildLatLng(mapDetails.location),
							zoom: 16,
							disableDefaultUI: true,
							mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
							// enables 1 finger pan gesture
							gestureHandling: 'greedy',
						}}
						markers={generateOptions()}
					/>
				</>
			)
		} else {
			return (
				<Container>
					<Search isLoading={isLoading} handleClick={handleClick} />
				</Container>
			)
		}
	}

	return (
		<Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}>
			{generateContent()}
			<ErrorModal
				errorMessage={errorMessage}
				modalDidClose={() => setErrorMessage(null)}
			/>
		</Wrapper>
	)
}

export default App
